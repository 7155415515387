import { log } from '../utils';

type TListener = (requestStackSize: number) => void;

const RO = {
  hasHookRequest: false,
  requestStackSize: 0,
  listeners: [] as TListener[],
  runListeners() {
    this.listeners.forEach((listener) => listener(this.requestStackSize));
  },
  hook() {
    // case2: fetch
    if (window.fetch) {
      const _fetch = window.fetch;
      window.fetch = function (...args) {
        RO.requestStackSize++;
        RO.runListeners();
        return _fetch.apply(window, args).then(
          (response: any) => {
            try {
              response
                .clone()
                .blob()
                .then(() => {
                  log('MCP：fetch请求结束');
                  RO.requestStackSize--;
                  RO.runListeners();
                })
                .catch(() => {
                  log('MCP：fetch请求结束');
                  RO.requestStackSize--;
                  RO.runListeners();
                });
            } catch (ex) {
              /* empty */
            }
            return response;
          },
          (err: Error) => {
            log('MCP：fetch请求失败');
            try {
              RO.requestStackSize--;
              RO.runListeners();
            } catch (ex) {
              /* empty */
            }
            throw err;
          },
        );
      };
    }

    // case3: XHR
    const _xhrSend = XMLHttpRequest.prototype.send;
    XMLHttpRequest.prototype.send = function (...args) {
      log('MCP：XHR请求开始', arguments);
      _xhrSend.apply(this, args);

      // abort、timeout、loadend等时间Edge在79以上才支持
      // 支持的环境下未设置时xhr.onloadend返回 null
      RO.requestStackSize++;
      RO.runListeners();
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const xhr = this;
      // abort后这个事件在早期浏览器上不会促发，要求：Firefox 51.0.1, Opera 43.0.2442.991, Safari 10.0.3 (12602.4.8), Chrome 54.0.2840.71, Edge, IE11
      // 要运行MCP浏览器要求会高于以上，故先这样处理
      xhr.addEventListener('readystatechange', () => {
        if (xhr.readyState === 4) {
          log('MCP：XHR请求结束', xhr);
          RO.requestStackSize--;
          RO.runListeners();
        }
      });
    };
  },
  addEventListener(callback: () => void, onlyHookStaticRequest = false) {
    if (!this.hasHookRequest && onlyHookStaticRequest === false) {
      this.hook();
      this.hasHookRequest = true;
    }
    this.listeners.push(callback);
  },
  removeEventListener(callback: () => void) {
    this.listeners = this.listeners.filter((item) => item !== callback);
  },
};

class RequestObserver {
  // 请求数量
  requestStackSize: number;
  // 资源数量
  assetsStack: number;
  end: number;
  observerFn: TListener;
  onlyHookStaticRequest: boolean;
  constructor(observer: TListener, onlyHookStaticRequest = false) {
    this.assetsStack = 0;
    this.requestStackSize = 0;
    this.observerFn = observer;
    this.onlyHookStaticRequest = onlyHookStaticRequest;
  }

  observer = () => {
    this.requestStackSize = Number(RO.requestStackSize) + Number(this.assetsStack);
    log('MCP：请求数量变化', this.requestStackSize, this.assetsStack, this.onlyHookStaticRequest);
    this.end !== 1 && this.observerFn(this.requestStackSize);
  };

  observe(el?: HTMLElement) {
    // case1: img\css\js\jsonp
    if (el) {
      this.assetsStack++;
      el.addEventListener('load', () => {
        this.assetsStack--;
        this.observer();
      });
      el.addEventListener('error', () => {
        this.assetsStack--;
        this.observer();
      });
      this.observer();
      return;
    }

    RO.addEventListener(this.observer, this.onlyHookStaticRequest);

    return this;
  }

  disconnect() {
    // 直接改回来会导致其它hook失效
    this.end = 1;
    RO.removeEventListener(this.observer);
  }
}

export default RequestObserver;
