import { getCommonInfo } from '@alife/cone-arms-core';
import { IPluginFunc } from './types';
import { inWindow, supportLCP, debugReport } from '../utils';
import { onFCP } from 'web-vitals';

const perfKeys: string[] = [
  'startTime',
  'unloadEventStart',
  'unloadEventEnd',
  'redirectStart',
  'responseEnd',
  'fetchStart',
  'domainLookupStart',
  'domainLookupEnd',
  'connectStart',
  'secureConnectionStart',
  'connectEnd',
  'requestStart',
  'responseStart',
  'redirectEnd',
  'domInteractive',
  'domContentLoadedEventStart',
  'domContentLoadedEventEnd',
  'domComplete',
  'loadEventStart',
  'loadEventEnd',
];

// 如果初始化插件时页面已经onload，直接获取performance并发送
// 如果初始化插件时页面还没有onload，则等页面onload时再获取performance并发送
const onLoad = function (callback: Function) {
  let timer: number;
  function setTimeoutOnWindow() {
    timer = setTimeout(callback);
  }
  if (document.readyState === 'complete') {
    timer = setTimeout(callback);
  } else {
    window.addEventListener('load', setTimeoutOnWindow);
  }
  return () => {
    if (timer) {
      clearTimeout(timer);
    }
    window.removeEventListener('load', setTimeoutOnWindow);
  };
};

class Perf {
  // 字段
  hasSendPerf: boolean;
  enableLCP: boolean;
  commonTimes: any;
  lcpTime: any;
  hiddenTotalTime = 0;
  hiddenStart = 0;
  logPerf: Function;
  logError: Function;
  uninstallHooks: Array<Function | undefined>;
  config: any;
  forceReport: boolean;
  // 构造函数
  constructor(enableLCP: boolean, core: any) {
    this.hasSendPerf = false;
    this.enableLCP = enableLCP && supportLCP();
    this.logPerf = core.logPerf;
    this.commonTimes = null;
    this.lcpTime = null;
    this.logError = core.logError;
    this.config = core.config;
    this.uninstallHooks = [];
  }

  getNavigationTiming = () => {
    let timing: any;
    // @ts-ignore
    if (window?.PerformanceNavigationTiming && window?.performance?.getEntriesByType) {
      const navigationPerfArray = performance.getEntriesByType('navigation');
      if (Array.isArray(navigationPerfArray)) {
        timing = navigationPerfArray[0];
      }
    }
    if (!(typeof timing === 'object' && timing) && window?.performance?.timing) {
      timing = window.performance.timing;
    }
    const perfData: any = {};
    if (typeof timing === 'object' && timing) {
      perfKeys.forEach((key) => {
        switch (key) {
          case 'startTime':
            perfData[key] = typeof timing[key] === 'number' ? timing[key] : timing.navigationStart;
            break;
          default:
            perfData[key] = timing[key];
            break;
        }
      });
    }
    return perfData;
  };

  getPaintTiming = () => {
    try {
      // @ts-ignore
      if (window.PerformanceNavigationTiming && window?.performance?.getEntriesByType) {
        const timing: any = performance.getEntriesByType('paint') || [];
        // 用filter代替find，兼容低版本浏览器，不包括IE
        // const firstPaint = timing.find((item: any) => item.name === 'first-paint');
        const firstPaint = timing.filter((item: any) => item.name === 'first-paint')[0];
        const firstPaintTime = (firstPaint && firstPaint.startTime) || 0;
        // const fcp = timing.find((item: any) => item.name === 'first-contentful-paint');
        const fcp = timing.filter((item: any) => item.name === 'first-contentful-paint')[0];
        const fcpTime = (fcp && fcp.startTime) || 0;
        return {
          fp: firstPaintTime,
          fcp: fcpTime,
        };
      }
    } catch (e) {
      // SDK自监控错误上报
      this.logError &&
        this.logError(e, {
          pid: 'plat-test-arms-dev',
        });
    }
    return {
      fp: 0,
      fcp: 0,
    };
  };

  getConnection = () => {
    let connectionData = {};
    // @ts-ignore
    const connection = window?.navigator?.connection;
    if (typeof connection === 'object' && connection) {
      // 网络连接类型 connection type,例：wifi, 3g,目前只在 chrome 中能拿到数据
      const net_type = connection.effectiveType || connection.type || '';
      // 网络带宽 connection bandwidth，单位 Mbps
      const downlink = connection.downlink || connection.downlinkMax || connection.bandwidth || '';
      connectionData = {
        net_type,
        downlink,
      };
    }
    return connectionData;
  };

  getCommonTiming = () => {
    // 提前获取当前页面的common info, 避免上报前切换页面
    const { ignoredQueries = [] } = this.config;
    const navigationTimes = this.getNavigationTiming();
    const paintTimes = this.getPaintTiming();
    const connectionTimes = this.getConnection();
    const commonInfo = getCommonInfo(ignoredQueries, this.config);
    const timing = Object.assign({}, commonInfo, navigationTimes, paintTimes, connectionTimes);
    return timing;
  };

  observeLCP = () => {
    // Catch errors since some browsers throw when using the new `type` option.
    // https://bugs.webkit.org/show_bug.cgi?id=209216
    try {
      let lcp: number;
      let timer: number;
      const po = new PerformanceObserver((entryList) => {
        const entries = entryList.getEntries();
        const lastEntry: any = entries[entries.length - 1];
        // Update `lcp` to the latest value, using `renderTime` if it's available,
        // otherwise using `loadTime`. (Note: `renderTime` may not be available on
        // image elements loaded cross-origin without the `Timing-Allow-Origin` header.)
        lcp = lastEntry.renderTime || lastEntry.loadTime;
        this.lcpTime = lcp;
      });
      po.observe({ type: 'largest-contentful-paint', buffered: true });

      const report = (forceReport = false) => {
        if (lcp || document.hidden || forceReport) {
          this.forceReport = forceReport;
          this.sendTiming();
          removeEventListener('visibilitychange', handleVisibilitychange, true);
          removeEventListener('beforeunload', handleBeforeunload);
          clearTimeout(timer);
        }
      };

      const handleVisibilitychange = () => {
        // 需要记录页面隐藏时间
        if (document.hidden) {
          this.hiddenStart = Date.now();
          clearTimeout(timer);
        } else if (this.hiddenStart > 0) {
          this.hiddenTotalTime += Date.now() - this.hiddenStart;
          this.hiddenStart = 0;
          initTimeout();
        }
        report();
      };

      const handleBeforeunload = () => {
        report(true);
      };

      const initTimeout = () => {
        // 最晚页面显示30秒后就触发上报，否则由于异常情况导致的数值太大没统计意义
        timer = window.setTimeout(() => {
          report(true);
        }, 30 * 1000);
      };

      if (document.hidden) {
        this.hiddenStart = Date.now();
      } else {
        initTimeout();
      }

      // Send data to the server.
      addEventListener('visibilitychange', handleVisibilitychange, true);
      addEventListener('beforeunload', handleBeforeunload);
      return () => {
        removeEventListener('visibilitychange', handleVisibilitychange, true);
        removeEventListener('beforeunload', handleBeforeunload);
        clearTimeout(timer);
      };
    } catch (e) {
      return () => {};
    }
  };

  sendTiming = () => {
    let paintTimes = this.getPaintTiming();
    const report = () => {
      const lcpTime = this.enableLCP !== true ? 0 : this.calculateLCP();
      if (
        typeof this.commonTimes === 'object' &&
        this.hasSendPerf === false &&
        typeof lcpTime === 'number'
      ) {
        const times = Object.assign(
          {},
          this.commonTimes,
          {
            lcp: lcpTime,
            c5: this.hiddenTotalTime,
          },
          paintTimes,
        );
        this.logPerf(times);
        this.hasSendPerf = true;
        if (times.fcp === 0 || (this.enableLCP === true && times.lcp === 0)) {
          debugReport({
            c2: 'perf',
            c3: this.enableLCP,
            c4: times.fcp,
            c5: times.lcp,
          });
        }
      }
    };
    // 如果采集不到fcp，延迟1s再上报，避免上报的数据不准确
    if (paintTimes.fcp === 0 && this.forceReport !== true) {
      const timer = setTimeout(() => {
        this.forceReport = true;
        paintTimes = this.getPaintTiming();
        report();
      }, 1000);
      onFCP((fcp) => {
        clearTimeout(timer);
        paintTimes.fcp = isNaN(fcp.value) ? 0 : fcp.value;
        report();
      });
    } else {
      report();
    }
  };

  calculateLCP = () => {
    // 计算lcp时间需要将页面隐藏时间去掉，否则会偏差较大
    let lcpTime = this.lcpTime || 0;
    const paintTimes = this.getPaintTiming();
    if (this.hiddenTotalTime > 0 || this.hiddenStart > 0) {
      let { hiddenTotalTime } = this;
      if (this.hiddenStart > 0) {
        hiddenTotalTime += Date.now() - this.hiddenStart;
      }
      if (
        lcpTime > hiddenTotalTime &&
        hiddenTotalTime > 0 &&
        lcpTime - hiddenTotalTime > paintTimes.fcp
      ) {
        lcpTime -= hiddenTotalTime;
        this.hiddenTotalTime = hiddenTotalTime;
      }
    }
    return lcpTime > 0 ? lcpTime : 0;
  };

  init = () => {
    this.uninstallHooks = [];
    this.uninstallHooks.push(
      onLoad(() => {
        const commonTimes = this.getCommonTiming();
        this.commonTimes = commonTimes;
        if (this.enableLCP !== true) {
          this.sendTiming();
        }
      }),
    );
    if (this.enableLCP === true) {
      this.uninstallHooks.push(this.observeLCP());
    }
  };
}

let perfInstance: Perf;

const TracePerfPlugin: IPluginFunc = (core, params = {}) => {
  if (!(core && inWindow() && typeof core.logPerf === 'function')) {
    return [];
  }
  // 性能上报需要单例，不重复上报
  if (perfInstance && perfInstance.uninstallHooks) {
    return perfInstance.uninstallHooks;
  }
  try {
    perfInstance = new Perf(params.enableLCP, core);
    perfInstance.init();
    return perfInstance.uninstallHooks;
  } catch (e) {
    console.warn(e);
  }
  return [];
};
if (inWindow()) {
  (window as any).TracePerfPlugin = TracePerfPlugin;
}
export default TracePerfPlugin;
