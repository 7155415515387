import Core, { getXPath, getCommonInfo } from '@alife/cone-arms-core';
import { inWindow, hookHistory } from '../utils';
import { IIDPluginFunc } from '../types';
import {
  isFTPPage,
  queryFilterHeight,
  queryTableHeight,
  queryTableWidth,
  queryPageHeight,
  queryTableVertical,
  queryTableHorizontal,
  getPage,
} from './density-util';

const win: any = inWindow() ? window : {};

class Density {
  private core: Core;
  /**
   * 打开时初始化时间
   */
  private initTime = Date.now();
  /**
   * 页面打开总的时间
   */
  private stayTime = 0;
  /**
   * table滚动次数
   */
  private tableCount = 0;
  /**
   * table横向滚动次数
   */
  private tableHorizontalCount = 0;
  /**
   * document次数
   */
  private documentCount = 0;
  /**
   * page 滚动次数
   */
  private pageCount = 0;
  /**
   * 首屏点击次数
   */
  private firstScreenClickCount = 0;
  /**
   * 屏幕总的点击次数
   */
  private totalClickCount = 0;
  /**
   * 滚动开始的y坐标
   */
  private startY = 0;
  /**
   * 滚动结束的y坐标
   */
  private endY = 0;
  /**
   * 横向滚动开始的x坐标
   */
  private horizontalStartX = 0;
  /**
   * 横向滚动结束的x坐标
   */
  private horizontalEndX = 0;
  /**
   * 定时器
   */
  private scrollTimer: number | any = null;
  /**
   * 不在首屏的时间
   */
  private notFirstScreenTime = 0;
  /**
   * 不在首屏开始的时间戳
   */
  private notFirstScreenStart = 0;
  /**
   * xpath和坐标集合
   */
  private hotPosition: object[] = [];
  /**
   * hash改变的timer
   */
  private timer: number | any = null;

  private unhook: Function;

  private config: any;
  /**
   * 基本信息
   */
  private commonInfo: any;

  constructor(core: Core) {
    this.core = core;
    this.config = core.config;
  }
  //
  /**
   * 事件绑定
   * @param  {DOM}   target
   * @param  {String}   type
   * @param  {Function} callback
   * @param  {Boolean}  isOnce
   * @param  {Boolean} useCapture
   * @return {Object} this
   */
  on = function (
    target: any,
    type: string,
    callback: Function,
    isOnce?: boolean,
    useCapture?: boolean,
  ) {
    if (target.addEventListener) {
      useCapture = useCapture === true ? useCapture : false;
      target.addEventListener(
        type,
        function __fn(this: any, e: any) {
          isOnce && target.removeEventListener(type, __fn, useCapture);
          callback.call(this, e);
        },
        useCapture,
      );
    } else if (target.attachEvent) {
      target.attachEvent(`on${type}`, function __fn(this: any, e: any) {
        isOnce && target.detachEvent(`on${type}`, __fn);
        callback.call(this, e);
      });
    }
  };

  off = function (target: any, type: string, callback: Function) {
    if (target.addEventListener) {
      target.removeEventListener(type, callback);
    } else if (target.attachEvent) {
      target.detachEvent(`on${type}`, callback);
    }
  };

  /**
   * load完成事件回调
   */
  handleLoad = () => {
    if (document.readyState === 'complete') {
      this.init();
    }
  };

  /**
   * 结束之前需要先计算，如果不在首屏，计算不在首屏时间
   */
  calculateNotInFirstScreenTime = () => {
    if (this.notFirstScreenStart !== 0) {
      this.notFirstScreenTime += Date.now() - this.notFirstScreenStart;
    }
  };
  /**
   * 处理document滚动
   */
  handleDocumentScroll = () => {
    const scrollTopHeight = document.documentElement.scrollTop;
    if (!this.startY) {
      this.startY = scrollTopHeight;
      return;
    }
    this.endY = scrollTopHeight;
    this.calculateOutOfFirstScreenTime(
      document.documentElement.clientHeight,
      this.startY,
      this.endY,
    );
    if (this.scrollTimer == null) {
      this.scrollTimer = setTimeout(() => {
        const height = document.documentElement.clientHeight;
        const scrollHeight = Math.abs(this.endY - this.startY);
        if (scrollHeight > height / 4) {
          this.documentCount += 1;
        }
        clearTimeout(this.scrollTimer);
        this.scrollTimer = null;
        this.startY = 0;
        this.endY = 0;
      }, 500);
    }
  };

  /**
   * 滚动时计算不在首屏时间
   * @param height
   * @param start
   * @param end
   */
  calculateOutOfFirstScreenTime = (height: number, start: number, end: number) => {
    // 离开首屏
    if (end - start > 0) {
      if (end > height / 4 && this.notFirstScreenStart === 0) {
        this.notFirstScreenStart = Date.now();
      }
    }
    if (end - start <= 0) {
      if (end < height / 4 && this.notFirstScreenStart !== 0) {
        this.notFirstScreenTime += Date.now() - this.notFirstScreenStart;
        this.notFirstScreenStart = 0;
      }
    }
  };

  /**
   * 获取当前位置
   * @param ele element
   * @returns  返回element位置
   */
  getPosition = (ele: HTMLElement): number[] => {
    const offsetLeftALL = ele.offsetLeft + ele.clientLeft;
    const offsetTopALL = ele.offsetTop + ele.clientTop;
    if (ele.offsetParent) {
      const [currentX, currentY] = this.getPosition(ele.offsetParent as HTMLElement);
      return [currentX + offsetLeftALL, currentY + offsetTopALL];
    } else {
      return [ele.offsetLeft + ele.clientLeft, ele.offsetTop + ele.clientTop];
    }
  };

  /**
   * 处理鼠标按下事件
   * @param e 事件
   */
  handleMouseDown = (e: MouseEvent) => {
    const [currentX, currentY] = this.getPosition(e.target as HTMLElement);
    const offsetLeftALL = e.offsetX + currentX;
    const offsetTopALL = e.offsetY + currentY;
    this.saveClickNodePosition(e, offsetLeftALL, offsetTopALL);
  };

  /**
   * 保存当前的点击位置
   * @param e 事件
   * @param offsetLeftALL 左边距
   * @param offsetTopALL  上边距
   */
  saveClickNodePosition = (e: MouseEvent, offsetLeftALL: number, offsetTopALL: number) => {
    const hasLockNode = this.isClickLockPosition(e);
    let horizontalTableScrollLeftPoition = 0;
    if (hasLockNode) {
      const horizontalTable = queryTableHorizontal();
      if (horizontalTable) {
        horizontalTableScrollLeftPoition = horizontalTable.scrollLeft;
      }
    }
    this.addFirstScreenCount(
      hasLockNode ? offsetLeftALL - horizontalTableScrollLeftPoition : offsetLeftALL,
      offsetTopALL,
    );
    this.hotPosition.push({
      element: getXPath(e.target, 3),
      position: {
        left: hasLockNode ? offsetLeftALL - horizontalTableScrollLeftPoition : offsetLeftALL,
        top: offsetTopALL,
      },
    });
  };

  /**
   * 添加首屏点击事件次数
   * @param offsetLeft 左边距
   * @param offsetTop 上边距
   */
  addFirstScreenCount = (offsetLeft: number, offsetTop: number) => {
    if (offsetLeft < window.innerWidth && offsetTop < window.innerHeight) {
      this.firstScreenClickCount += 1;
    }
    this.totalClickCount += 1;
  };

  /**
   * 判断点击位置是否是锁定的
   * @param ele element
   * @returns true 是锁定的 false不是锁定的
   */
  judgeLockByClassAttribute = (ele: HTMLElement): boolean => {
    if (ele.parentNode) {
      const cl = ele.getAttribute('class');
      if (cl && (cl.indexOf('lock-right') > -1 || cl.indexOf('lock-left') > -1)) {
        return true;
      } else if (ele.parentNode) {
        return this.judgeLockByClassAttribute(ele.parentNode as HTMLElement);
      }
      return false;
    }
    return false;
  };

  /**
   * 是否点击了lock位置
   * @param e element
   * @returns true 是锁定的 false不是锁定的
   */
  isClickLockPosition = (e: MouseEvent) => {
    return this.judgeLockByClassAttribute(e.target as HTMLElement);
  };

  /**
   * 处理page滚动事件
   */
  handlePageScroll = (e: MouseEvent) => {
    const ele = e.target as HTMLElement;
    const scrollTopHeight = ele?.scrollTop;
    if (!this.startY) {
      this.startY = scrollTopHeight;
      return;
    }
    this.endY = scrollTopHeight;
    const page = getPage();
    if (page) {
      this.calculateOutOfFirstScreenTime(page.clientHeight, this.startY, this.endY);
    }
    if (this.scrollTimer == null) {
      this.scrollTimer = setTimeout(() => {
        const height = queryPageHeight();
        const scrollHeight = Math.abs(this.endY - this.startY);
        if (scrollHeight > height / 4) {
          this.pageCount += 1;
        }
        clearTimeout(this.scrollTimer);
        this.scrollTimer = null;
        this.startY = 0;
        this.endY = 0;
      }, 500);
    }
  };

  /**
   * 处理table滚动事件
   * @param e element
   */
  handleTableScroll = (e: MouseEvent) => {
    const ele = e.target as HTMLElement;
    const scrollTopHeight = ele?.scrollTop;
    if (!this.startY) {
      this.startY = scrollTopHeight;
    }
    this.endY = scrollTopHeight;
    if (this.scrollTimer == null) {
      this.scrollTimer = setTimeout(() => {
        const height = queryTableHeight();
        const scrollHeight = Math.abs(this.endY - this.startY);
        if (scrollHeight > height / 4) {
          this.tableCount += 1;
        }
        clearTimeout(this.scrollTimer);
        this.scrollTimer = null;
        this.startY = 0;
        this.endY = 0;
      }, 500);
    }
  };

  /**
   * 处理table水平滚动事件
   * @param e element
   */
  handleTableHorizontalScroll = (e: MouseEvent) => {
    const ele = e.target as HTMLElement;
    const scrollLeftOffset = ele?.scrollLeft;
    if (!this.horizontalStartX) {
      this.horizontalStartX = scrollLeftOffset;
    }
    this.horizontalEndX = scrollLeftOffset;
    if (this.scrollTimer == null) {
      this.scrollTimer = setTimeout(() => {
        const height = queryTableWidth();
        const scrollWidth = Math.abs(this.horizontalEndX - this.horizontalStartX);
        if (scrollWidth > height / 4) {
          this.tableHorizontalCount += 1;
        }
        clearTimeout(this.scrollTimer);
        this.scrollTimer = null;
        this.horizontalStartX = 0;
        this.horizontalEndX = 0;
      }, 500);
    }
  };

  /**
   * history事件
   */
  historyChange = () => {
    this.handlePageChange();
    this.timer = setTimeout(this.init, 1000);
  };

  /**
   * 处理history事件
   */
  handlePageChange = () => {
    if (isFTPPage()) {
      this.reportDensity();
      this.unbindElementEvent();
    }
  };
  /**
   * 取消事件绑定
   */
  unbindElementEvent = () => {
    this.unBindPage();
    this.unbindDocument();
    this.unBindTable();
    this.unBindHorizontalTable();
    clearTimeout(this.timer);
    this.resetParams();
  };

  /**
   *
   * 卸载页面时回调
   */
  handleFinish = () => {
    if (isFTPPage()) {
      this.reportDensity();
      this.unbindElementEvent();
      this.off(win, 'load', this.handleLoad);
      this.off(win, 'mousedown', this.handleMouseDown);
      this.off(win, 'beforeunload', this.handleFinish);
      this.unhook && this.unhook();
    }
  };

  /**
   * 重制参数
   */
  resetParams = () => {
    this.initTime = Date.now();
    this.stayTime = 0;
    this.tableCount = 0;
    this.tableHorizontalCount = 0;
    this.pageCount = 0;
    this.documentCount = 0;
    this.firstScreenClickCount = 0;
    this.totalClickCount = 0;
    this.startY = 0;
    this.endY = 0;
    this.horizontalStartX = 0;
    this.horizontalEndX = 0;
    this.scrollTimer = null;
    this.notFirstScreenTime = 0;
    this.notFirstScreenStart = 0;
    this.hotPosition = [];
    this.timer = null;
  };

  /**
   * 上传信息密度数据
   */
  reportDensity = () => {
    this.calculateNotInFirstScreenTime();
    const table = queryTableVertical();
    if (isFTPPage()) {
      this.stayTime = Date.now() - this.initTime;
      // console.log(
      //   'stayTime',
      //   this.stayTime,
      //   'tableCount',
      //   this.tableCount,
      //   'tableHorizontalCount',
      //   this.tableHorizontalCount,
      //   'pageCount',
      //   this.pageCount,
      //   'documentCount',
      //   this.documentCount,
      //   'firstScreenClickCount',
      //   this.firstScreenClickCount,
      //   'totalClickCount',
      //   this.totalClickCount,
      //   'notFirstScreenTime',
      //   this.notFirstScreenTime === 0 ? this.stayTime : this.stayTime - this.notFirstScreenTime,
      //   'filterHeight',
      //   queryFilterHeight(),
      //   'tableHeight',
      //   queryTableHeight(),
      //   'cellCount',
      //   table ? table.querySelectorAll('thead>tr>th').length : 0,
      // );
      const allInformationDensityData = Object.assign({}, this.commonInfo, {
        p1: 'information-density',
        p10: this.stayTime,
        p11: this.tableCount,
        p12: this.tableHorizontalCount,
        p13: this.pageCount,
        p14: this.documentCount,
        p15:
          this.notFirstScreenTime === 0 ? this.stayTime : this.stayTime - this.notFirstScreenTime,
        p16: queryFilterHeight(),
        p17: queryTableHeight(),
        p18: this.firstScreenClickCount,
        p19: this.totalClickCount,
        p20: table ? table.querySelectorAll('thead>tr>th').length : 0,
        p21: JSON.stringify(this.hotPosition),
      });
      // console.log('allData', allData);
      this.core.logCustom(allInformationDensityData);
    }
  };

  /**
   * 取消绑定
   */
  unBindPage = () => {
    const page = getPage();
    if (page) {
      this.off(page, 'scroll', this.handlePageScroll);
    }
  };

  /**
   * 绑定页面
   */
  bindPage = () => {
    const page = getPage();
    if (page) {
      this.on(page, 'scroll', this.handlePageScroll);
    }
  };

  /**
   * 绑定document
   */
  bindDocument = () => {
    this.on(document, 'scroll', this.handleDocumentScroll);
  };

  /**
   * 取消绑定document
   */
  unbindDocument = () => {
    this.off(document, 'scroll', this.handleDocumentScroll);
  };

  /**
   * 绑定table
   */
  bindTable = () => {
    const table = queryTableVertical();
    if (table) {
      this.on(table, 'scroll', this.handleTableScroll);
    }
  };
  /**
   * 取消绑定table
   */
  unBindTable = () => {
    const table = queryTableVertical();
    if (table) {
      this.off(table, 'scroll', this.handleTableScroll);
    }
  };
  /**
   * 绑定横向滚动table
   */
  bindHorizontalTable = () => {
    const table = queryTableHorizontal();
    if (table) {
      this.on(table, 'scroll', this.handleTableHorizontalScroll);
    }
  };
  /**
   * 取消绑定横向滚动table
   */
  unBindHorizontalTable = () => {
    const table = queryTableHorizontal();
    if (table) {
      this.off(table, 'scroll', this.handleTableHorizontalScroll);
    }
  };
  /**
   * 如果是FTP页面，绑定事件
   */
  init = () => {
    if (win && isFTPPage()) {
      this.resetParams();
      this.bindPage();
      this.bindDocument();
      this.bindTable();
      this.bindHorizontalTable();
    }
    this.getCommonData();
  };

  getCommonData = () => {
    const { ignoredQueries = [] } = this.config;
    this.commonInfo = getCommonInfo(ignoredQueries, this.config);
  };

  initPageScrollEventReport = () => {
    this.on(win, 'beforeunload', this.handleFinish);
    this.on(win, 'mousedown', this.handleMouseDown);
    this.on(win, 'load', this.handleLoad);
    this.unhook = hookHistory(this.historyChange);
    return () => {
      this.unbindElementEvent();
      this.off(win, 'load', this.handleLoad);
      this.off(win, 'mousedown', this.handleMouseDown);
      this.off(win, 'beforeunload', this.handleFinish);
      this.unhook && this.unhook();
    };
  };
}

let densityInstance: Density;

const DensityPlugin: IIDPluginFunc = (core: Core) => {
  if (!(core && inWindow())) {
    return;
  }
  // 信息密度上报需要单例，不重复上报
  if (densityInstance) {
    return;
  }
  densityInstance = new Density(core);
  return densityInstance.initPageScrollEventReport();
};

if (inWindow()) {
  (window as any).DensityPlugin = DensityPlugin;
}
export default DensityPlugin;
