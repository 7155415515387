import Core, { LogItem, IPlugin, userTypeMap, accountTypeMap } from '@alife/cone-arms-core';
import traceSdk from './sdk';
import TracePvPlugin from '@alife/cone-arms-plugin-pv';
import TracePerfPlugin from './perf-plugin';
import TraceApiPlugin from '@ali/trace-plugin-api';
import TraceResourceErrorPlugin from '@ali/trace-plugin-resource-error';
import TraceBlankScreenPlugin from '@alife/cone-arms-plugin-blank-screen';
import {
  validatePid,
  getVersion,
  genApiPluginOptions,
  inWindow,
  parseReportPayload,
  getPageDevType,
  getSpmAB,
  isDebug,
  supportLCP,
  getCNUIVersion,
  getClientInfo,
} from './utils';
import { InitTraceOptions, IUxPluginFunc, IIDPluginFunc } from './types';
import TraceUxPlugin from './ux-plugin';

import TraceMCPPlugin from './mcp-plugin';
import DensityPlugin from './density-plugin';
import { TTrace, extendTraceInstance } from './extend-trace';
import traceInstance from './global-trace';
import defaultSetting from './setting.json';

const { sdkVersion } = defaultSetting;

declare global {
  interface Window {
    trace?: TTrace;
    coneArmsTrace?: TTrace;
    TraceUxPlugin: IUxPluginFunc;
    DensityPlugin: IIDPluginFunc;
    parseArmsReportPayload: (payload: { gokey: string; gmkey?: string; logtype?: string }) => void;
    goldlog_queue?: Array<{
      action: string;
      arguments: any[];
    }>;
    getConeArmsReportHistory: () => LogItem[];
  }
}

const reportHistory = [] as LogItem[];

/**
 * 添加coneArms默认上报参数
 * @param payload
 * @returns
 */
function addConeArmsDefaultReportParams(payload: LogItem) {
  const needAddCNParamsReportTypes = [
    'pv',
    'api',
    'perf',
    'event',
    'error',
    'resource_error',
    'action',
  ];
  const extParams: Partial<LogItem> = getClientInfo();
  if (payload && needAddCNParamsReportTypes.indexOf(payload.type) > -1) {
    // 上报页面开发类型
    if (typeof payload.p25 === 'undefined') {
      extParams.p25 = getPageDevType();
    }
  }
  if (payload && payload.type === 'pv') {
    // 上报浏览器语言和时区设置
    if (typeof payload.p1 === 'undefined') {
      extParams.p1 = navigator.language;
    }
    const dateIns = new Date();
    if (typeof payload.p2 === 'undefined' && typeof dateIns.getTimezoneOffset === 'function') {
      const hourOffset = 0 - Math.ceil(dateIns.getTimezoneOffset() / 60);
      extParams.p2 = `UTC${hourOffset > 0 ? '+' : ''}${hourOffset}`;
    }
    // 上报cnui版本号信息
    if (typeof payload.p3 === 'undefined') {
      extParams.p3 = JSON.stringify(getCNUIVersion());
    }
  }
  const needAppendReportParams: any = {};
  Object.keys(extParams).forEach((key) => {
    if (typeof payload[key] === 'undefined') {
      needAppendReportParams[key] = extParams[key];
    }
  });
  Object.assign(payload, needAppendReportParams);
  return payload;
}

function beforeSendHook(payload: LogItem) {
  const retItem = addConeArmsDefaultReportParams(payload);
  if (isDebug()) {
    reportHistory.push(payload);
  }
  return retItem;
}

function initTrace<T = unknown>(options: InitTraceOptions): TTrace<T> {
  const {
    pid,
    checkPid = true,
    mountToWindow = true,
    pvPluginOptions = {},
    perfPluginOptions = {},
    apiPluginOptions = {},
    uxPluginOptions = {},
    mcpPluginOptions = {},
    blankScreenPluginOptions = {},
    morePlugins = [],
    defaultPluginSwitch = {},
    ...traceOptions
  } = options;

  validatePid({ pid, checkPid });
  const defaultPlugins = [];
  if (defaultPluginSwitch.disabledPvPlugin !== true) {
    defaultPlugins.push([TracePvPlugin, { autoPV: false, ...pvPluginOptions }]);
  }
  if (defaultPluginSwitch.disabledPerfPlugin !== true) {
    const canReportLCP = supportLCP();
    defaultPlugins.push([
      TracePerfPlugin,
      {
        enableLCP: canReportLCP,
        ...perfPluginOptions,
      },
    ]);
  }
  if (defaultPluginSwitch.disabledApiPlugin !== true) {
    defaultPlugins.push([TraceApiPlugin, genApiPluginOptions(apiPluginOptions)]);
  }
  if (defaultPluginSwitch.disabledResourceErrorPlugin !== true) {
    defaultPlugins.push([TraceResourceErrorPlugin]);
  }
  if (defaultPluginSwitch.disabledUxPlugin !== true) {
    defaultPlugins.push([TraceUxPlugin, uxPluginOptions]);
  }

  if (
    defaultPluginSwitch.disabledMCPPlugin !== true &&
    mcpPluginOptions &&
    'auto' in mcpPluginOptions
  ) {
    defaultPlugins.push([TraceMCPPlugin, { auto: true, ...mcpPluginOptions }]);
  }

  if (defaultPluginSwitch.disableDensityPlugin !== true) {
    defaultPlugins.push([DensityPlugin]);
  }

  if (defaultPluginSwitch.disabledBlankScreenPlugin !== true) {
    defaultPlugins.push([
      TraceBlankScreenPlugin,
      Object.assign(
        {
          detectedDom: ['#root', '#spa-root'],
          isPolling: true,
        },
        blankScreenPluginOptions,
      ),
    ]);
  }

  let plugins;
  if (morePlugins.length > 0 || defaultPlugins.length > 0) {
    plugins = [...defaultPlugins, ...morePlugins] as IPlugin[];
  }

  const version = getVersion();

  const originTrace = traceSdk({
    pid, // 必填 平台上申请的的pid
    env: 'prod', // 可选 prod | pre | daily | string
    version,
    // aplusUrl: 'gm.mmstat.com', // 可选 默认gm.mmstat.com, 海外埋点配置 sg.mmstat.com
    plugins,
    ...traceOptions,
  }) as unknown as Core & T; // traceSdk 不支持泛型 目前只能强制断言且需要通过 unknown 转义

  const newTrace = extendTraceInstance<T>(originTrace);

  if (newTrace.logApi) {
    const originLogApi = newTrace.logApi.bind(newTrace);

    newTrace.logApi = (logItem: any = {}) => {
      const { _originRequestIns, ...realLogItem } = logItem;
      if (_originRequestIns) {
        setTimeout(() => {
          originLogApi({
            ...realLogItem,
            p15: _originRequestIns.__errorMessageReadCount || 0,
          });
        }, 100);
      } else {
        originLogApi(realLogItem);
      }
    };
  }

  // 注册全局hooks，并启动 trace 并监听全局 JS 异常
  if (newTrace._hasInstalledPlugin !== true) {
    newTrace.install();
    newTrace.before('send', (payload: LogItem) => {
      const newPayload = beforeSendHook(payload);
      const spm = getSpmAB(newTrace.getConfig());
      return {
        ...newPayload,
        spm_a: newPayload.spm_a || spm.spm_a,
        spm_b: newPayload.spm_b || spm.spm_b,
      };
    });
    newTrace._hasInstalledPlugin = true;
  }

  // mount to window
  if (mountToWindow !== false && inWindow() && !window.coneArmsTrace) {
    window.trace = newTrace;
    window.coneArmsTrace = newTrace;
    window.parseArmsReportPayload = parseReportPayload;
    window.getConeArmsReportHistory = () => reportHistory;
  }

  return newTrace;
}

function getConeArmsSpaAutoPv(): () => null {
  throw new Error('getConeArmsSpaAutoPv, 已废弃，直接配置 pvPluginOptions.autoPV=true 即可');
}

export {
  initTrace,
  getConeArmsSpaAutoPv,
  TraceUxPlugin,
  DensityPlugin,
  traceInstance,
  userTypeMap,
  sdkVersion,
  accountTypeMap,
};

export type { TTrace };
