import { ITraceWithCustomMethods } from "./types";
import CustomEventManager from "./custom-event-manager";

/* eslint-disable no-console */
const getGlobalTrace = () => {
  return typeof window !== 'undefined' && window.coneArmsTrace ? window.coneArmsTrace : null;
};

export default new Proxy({} as any, {
  get: (_oTarget, key) => {
    const globalTrace = getGlobalTrace();
    if (globalTrace) {
      if (key in globalTrace) {
        return globalTrace[key as keyof typeof globalTrace];
      }
      return () => console.warn(`当前运行环境的arms上报实例，不存在${String(key)}方法`);
    }
    return () => {
      console.warn(`当前运行环境没有初始化好的arms上报实例，调用${String(key)}方法无效`);
      if (key === 'initCustomEventReport') {
        return new Proxy(
          {},
          {
            get: () => {
              return () => {};
            },
          },
        );
      }
      return {};
    };
  },
}) as ITraceWithCustomMethods<CustomEventManager>;
