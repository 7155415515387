import Core, { LogItem, uuid as genUuid } from '@alife/cone-arms-core';
import { ICustomEventReportInitOptions, ICustomEventReportOptions } from './types';

const ACTION = {
  start: 'custom-flow-start',
  end: 'custom-flow-end',
};

class CustomEventManager {
  static instanceMap: {
    [key: string]: CustomEventManager;
  } = {};

  static getInstance(instanceId: string) {
    if (instanceId && CustomEventManager.instanceMap[instanceId]) {
      return CustomEventManager.instanceMap[instanceId];
    }
    return null;
  }
  static destroyInstance(instanceId: string) {
    if (instanceId && CustomEventManager.instanceMap[instanceId]) {
      delete CustomEventManager.instanceMap[instanceId];
    }
  }
  static log(instanceId: string, options: ICustomEventReportOptions) {
    const instance = CustomEventManager.getInstance(instanceId);
    if (instance instanceof CustomEventManager) {
      instance.log(options);
    }
  }
  private extParams: Partial<LogItem> = {};
  private taskName = '';
  private startTime = 0;
  private lastEventTime = 0;
  private trace: Core;
  private uuId = genUuid();
  private isFinish = false;
  constructor(trace: Core, options: ICustomEventReportInitOptions) {
    this.trace = trace;
    const { taskName, autoReportStart = true } = options;
    const customFields = ['c1', 'c2', 'c3', 'c4', 'c5', 'c6', 'c7', 'c8', 'c9', 'c10'];
    if (!taskName || typeof taskName !== 'string') {
      throw new Error('taskName is required');
    }
    if (!(taskName.indexOf('page.') === 0 || taskName.indexOf('cn-component.') === 0)) {
      console.warn(`
        taskName must start with page. or cn-component.
        命名规范:
        1. 菜鸟公共组件 - cn-component.{组件名}.{事务名}
        2. 页面自定义事务 - page.{页面名}.{事务名}
      `);
    }
    this.taskName = taskName;
    this.extParams = {};
    customFields.forEach((field: keyof ICustomEventReportInitOptions) => {
      if (options[field]) {
        this.extParams[field] = options[field];
      }
    });
    CustomEventManager.instanceMap[this.uuId] = this;
    if (autoReportStart === true) {
      this.logStart();
    }
  }
  log(options: ICustomEventReportOptions) {
    const { action, success, ...rest } = options;
    if (!action) {
      console.error('action is require');
      return;
    }
    if (this.isFinish) {
      console.error('this instance has been report end');
      return;
    }
    if (this.startTime === 0) {
      // 还没上报过开始事件，自动帮上报一次
      this.logStart();
      this.log(options);
    } else {
      const { taskName, extParams, lastEventTime, uuId, startTime } = this;
      const currentTime = Date.now();
      let result = action === ACTION.start ? 'commit' : 'process';
      if (action === ACTION.end) {
        result = success === true ? 'success' : 'failure';
      }
      const logData = this.trace.transformLogParam(
        'action',
        Object.assign({}, extParams, rest, {
          action: taskName,
          actionType: action,
          result,
          duration: currentTime - startTime,
          p18: uuId,
          p19: currentTime - lastEventTime,
          // 增加上报标识，便于区分数据
          p20: 'cn-custom-flow-report-1.0.0',
        }),
      );
      this.trace.log(logData);
      this.lastEventTime = currentTime;
    }
  }
  logStart(options: Partial<ICustomEventReportOptions> = {}) {
    if (this.startTime > 0) {
      console.error('this instance has been report start');
      return;
    }
    this.startTime = Date.now();
    this.lastEventTime = this.startTime;
    this.log({
      ...options,
      action: ACTION.start,
    });
  }
  logEnd(options: Partial<ICustomEventReportOptions> = {}) {
    const { success = true, ...rest } = options || {};
    this.log({
      ...rest,
      success,
      action: ACTION.end,
    });
    this.isFinish = true;
    // 结束后自动销毁
    CustomEventManager.destroyInstance(this.uuId);
  }
  getInstanceId() {
    return this.uuId;
  }
}

export default CustomEventManager;
