/**
 * @author zhongxinzhi
 * @date 2020/8/23 8:09 下午
 */
export const APLUS_LOGKEY = '/cnux.1.0';
export const APLUS_HOST = 'gm.mmstat.com';
export const APLUS_URL = `https://${APLUS_HOST}${APLUS_LOGKEY}`;
export const COOKIE_UID_KEY = 'arms_uid';
export const COOKIE_UID_MAXAGE = 180 * 24 * 3600;
export const PREF_APLUS_LOGKEY = '/cnux.1.0';
export const PREF_APLUS_URL = `${APLUS_HOST}${PREF_APLUS_LOGKEY}`;
export const VALUE_LIMIT = 512;
