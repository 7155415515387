class InputObserver {
  inputTypes: string[];
  eventHandler: () => void;
  constructor(observer: () => void) {
    this.inputTypes = ['click', 'keypress', 'wheel'];
    this.eventHandler = observer;
  }
  observer() {
    this.inputTypes.forEach((type) => {
      window.addEventListener(type, this.eventHandler, true);
    });
  }

  disconnect() {
    this.inputTypes.forEach((type) => {
      window.removeEventListener(type, this.eventHandler, true);
    });
  }
}

export default InputObserver;
