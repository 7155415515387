import { LogItem } from '@alife/cone-arms-core';
import { on } from 'delegated-events';
import { getXPath } from '@alife/cone-arms-core';
import { IUxPluginFunc } from '../types';
import { getHottagName, getHottagGroup, inWindow, setMaxFindHottagDataDomLevel } from '../utils';

const defaultHookHottagReport = () => {};

const TraceUxPlugin: IUxPluginFunc = (core, opt) => {
  if (!(core && inWindow()) || core._hasInstalledPlugin) {
    return;
  }
  const {
    enableHottagReport = true,
    hottagReportSampling = 0.1,
    hookHottagReport = defaultHookHottagReport,
    maxFindHottagDataDomLevel = 20,
  } = opt || {};
  setMaxFindHottagDataDomLevel(maxFindHottagDataDomLevel);
  if (enableHottagReport === true || enableHottagReport === 'CLK') {
    on('click', '[data-hottag]', (event) => {
      const eventTarget = event.currentTarget as HTMLElement;
      const hottag = eventTarget.getAttribute('data-hottag');
      const hottagType = eventTarget.getAttribute('data-hottag-type');
      if (hottag && (!hottagType || hottagType === 'CLK')) {
        try {
          const extParams = (hookHottagReport(hottag, 'CLK', eventTarget) || {}) as LogItem;
          const hottagExtParamsStr = eventTarget.getAttribute('data-hottag-ext-params');
          if (hottagExtParamsStr) {
            let hottagExtParams = {};
            try {
              hottagExtParams = JSON.parse(hottagExtParamsStr);
            } catch (e) {
              // handle empty block
              hottagExtParams = {};
            }
            Object.assign(extParams, hottagExtParams);
          }
          const sampling =
            extParams && extParams.sampling ? extParams.sampling : hottagReportSampling;
          core.reportHottag({
            ...extParams,
            hottag,
            sampling,
            eventType: 'CLK',
            name: getHottagName(eventTarget),
            group: getHottagGroup(eventTarget),
            xpath: getXPath(eventTarget, 5),
          });
        } catch (e) {
          // handle empty block
        }
      }
    });
  }
  // if (enablePageScrollReport) {
  //   initPageScrollEventReport(core, pageScrollReportOptions);
  // }
};

if (inWindow()) {
  window.TraceUxPlugin = TraceUxPlugin;
}
export default TraceUxPlugin;
