import { Config as ApiPluginOptions } from '@ali/trace-plugin-api/types/types/index.type';
import { IResponse, IApiEvaluateResult, InitTraceOptions, LogCustomItem } from './types';
import UAParser from 'ua-parser-js';

let maxFindHottagDataDomLevel = 5;

export function validatePid({ pid, checkPid }: { pid?: string; checkPid?: boolean }) {
  if (!pid) {
    throw new Error('pid 是必填参数');
  }

  if (typeof pid !== 'string') {
    throw new Error('pid 必须是字符串');
  }

  if (checkPid !== false) {
    if (pid.indexOf('cn_cone_') !== 0 && pid.indexOf('cn-cone-') !== 0) {
      const msg = `!!! arms pid '${pid}' 不符合规范, 必须以 cn_cone_ 或者 cn-cone- 开头`;
      // eslint-disable-next-line no-console
      console.warn(msg);
    }
  }
}

export function getVersion() {
  try {
    return process.env.CN_APP_VERSION;
  } catch (err) {
    return undefined;
  }
}

export function getXHRHeaderByName(xhr: XMLHttpRequest, headerName: string) {
  if (xhr && xhr.getAllResponseHeaders && xhr.getAllResponseHeaders().indexOf(headerName) > -1) {
    return xhr.getResponseHeader(headerName) as string;
  }
  return '';
}

export function getResponseTraceId(response: IResponse, xhr: XMLHttpRequest) {
  let traceId = '';
  if (response && response.traceId) {
    traceId = response.traceId;
  } else if (xhr && typeof xhr.getAllResponseHeaders === 'function') {
    traceId = getXHRHeaderByName(xhr, 'x-trace-id') || getXHRHeaderByName(xhr, 'eagleeye-traceid');
  }
  return traceId;
}

export function genApiPluginOptions(options: ApiPluginOptions) {
  const { evaluate, ...rest } = options;
  const evaluateHook = (...args: [IResponse, XMLHttpRequest]) => {
    const [originResponse, xhr] = args;
    let response = originResponse;
    if (typeof response === 'string') {
      try {
        response = JSON.parse(response);
      } catch (err) {
        // ignore
      }
    }
    const ret: Partial<IApiEvaluateResult> = {};
    const traceId = getResponseTraceId(response, xhr);
    if (traceId) {
      ret.traceId = traceId;
    }
    if (response && response.success === false) {
      // response.success === false 不走采样直接上报
      ret.sampling = 1;
      // 新的统一接口规范
      if (response.errorCode) {
        ret.code = response.errorCode;
        ret.msg = response.errorMsg || response.msg;
      } else if (response.error) {
        // 兼容旧的规范
        ret.msg = response.error.message;
        ret.code = response.error.code;
      }
      // 业务逻辑，记录response
      ret.response = JSON.stringify(response);

      // 暂存request对象，用于后续获取额外信息
      ret._originRequestIns = xhr;
    }
    if (response && typeof response === 'object' && typeof response.success === 'boolean') {
      ret.p12 = response.success ? 'true' : 'false';
    }
    ret.p14 = 'true';
    ['success', 'msg', 'errorCode', 'errorMsg', 'data', 'traceId'].forEach((key) => {
      if (!(response && typeof response === 'object' && key in response)) {
        ret.p14 = 'false';
      }
    });
    if (typeof evaluate === 'function') {
      Object.assign(ret, evaluate(...args));
    }
    if ('bizExtParams' in ret) {
      ret.p13 = ret.bizExtParams;
      delete ret.bizExtParams;
    }
    return ret;
  };
  return {
    sampling: 1,
    ...rest,
    evaluate: evaluateHook,
  };
}

export function getHottagName(eventTarget: HTMLElement, elementLevel?: number): string {
  const hottagName = !elementLevel ? eventTarget.getAttribute('data-hottag-name') : '';
  if (hottagName) {
    return hottagName;
  }
  if (eventTarget.innerText) {
    return eventTarget.innerText;
  }
  const nextElementLevel = typeof elementLevel === 'number' ? elementLevel + 1 : 1;
  const parent = eventTarget.parentElement;
  if (parent && nextElementLevel < maxFindHottagDataDomLevel) {
    return getHottagName(parent, nextElementLevel);
  }
  return '';
}

export function getHottagGroup(eventTarget: HTMLElement, elementLevel?: number): string {
  const hottagName = eventTarget.getAttribute('data-hottag-group');
  if (hottagName) {
    return hottagName;
  }
  const nextElementLevel = typeof elementLevel === 'number' ? elementLevel + 1 : 1;
  if (nextElementLevel > maxFindHottagDataDomLevel) {
    return '';
  }
  const parent = eventTarget.parentElement;
  if (parent) {
    return getHottagGroup(parent, nextElementLevel);
  }
  return '';
}

export function inWindow() {
  return typeof window !== 'undefined';
}

export function parseReportPayload(payload: { gokey: string; gmkey?: string; logtype?: string }) {
  if (payload && payload.gokey) {
    const params = payload.gokey.split('&');
    const paramsObject = params.reduce((result: Record<string, string>, item) => {
      const data = item.split('=');
      return {
        ...result,
        [data[0]]: decodeURIComponent(decodeURIComponent(data[1])),
      };
    }, {});
    // eslint-disable-next-line no-console
    // console.log('arms上报参数对象：', paramsObject);
    return paramsObject;
  }
  return null;
}

/**
 * 获取当前页面的研发类型
 * @link https://yuque.antfin-inc.com/cn-xt/arch/db7m8e
 * @returns string
 */
export function getPageDevType() {
  if (typeof document === 'undefined' || !document.getElementById) {
    return '';
  }
  const dom = document.getElementById('mod-cn-cone-page-level');
  if (dom) {
    return dom.getAttribute('data-level') || '';
  }
  return '';
}

export function setMaxFindHottagDataDomLevel(num: number) {
  if (num > 0) {
    maxFindHottagDataDomLevel = num;
  }
}

export function hookHistory(onChange: () => void) {
  const win = window || {};
  let onHistoryChange: any = onChange;
  const handleHistoryChange = () => {
    if (typeof onHistoryChange === 'function') {
      onHistoryChange();
    }
  };
  try {
    // hook onpopstate
    const oldPopstate = win.onpopstate;
    win.onpopstate = function (this: any, ...args: any[]) {
      if (typeof oldPopstate === 'function') {
        return oldPopstate.apply(this, args);
      }
      handleHistoryChange();
    };
    // hook pushState
    if (win.history && typeof win.history.pushState === 'function') {
      const oldPushState = win.history.pushState;
      const newPushState = function (this: any, ...args: any[]) {
        oldPushState.apply(this, args);
        handleHistoryChange();
      };
      win.history.pushState = newPushState;
    }
    // hook replaceState
    if (win.history && typeof win.history.replaceState === 'function') {
      const oldReplaceState = win.history.replaceState;
      const newReplaceState = function (this: any, ...args: any[]) {
        oldReplaceState.apply(this, args);
        handleHistoryChange();
      };
      win.history.replaceState = newReplaceState;
    }
  } catch (error) {
    console.warn('[cone-arms] error in hookHistory', error);
  }
  return () => {
    onHistoryChange = null;
  };
}

export function getSpmAB(config: InitTraceOptions = {}) {
  const metaSpm = document.querySelector('meta[name="data-spm"]');
  const result: {
    spm_a: string;
    spm_b: string | number;
  } = {
    spm_a: '',
    spm_b: '',
  };
  if (metaSpm && metaSpm.getAttribute('content')) {
    result.spm_a = metaSpm.getAttribute('content') || '';
    result.spm_b = document.querySelector('body[data-spm]')?.getAttribute('data-spm') || '';
  }
  if (!result.spm_a || !result.spm_b) {
    const { spm_a = '', spm_b = '' } = config;
    result.spm_a = result.spm_a || spm_a;
    result.spm_b = result.spm_b || spm_b;
  }
  if (!result.spm_a || !result.spm_b) {
    const { goldlog } = window as any;
    const spm_ab = goldlog?.spm_ab || goldlog?.spmAb || [];
    // console.log('goldlog', goldlog);
    result.spm_a = result.spm_a || spm_ab[0] || '';
    result.spm_b = result.spm_b || spm_ab[1] || '';
  }
  return result;
}

export const isDebug = () => {
  if (!inWindow()) {
    return false;
  }
  try {
    if (process.env.NODE_ENV === 'development') {
      return true;
    }
  } catch {
    // ignore
  }
  const { location } = window;
  return location.search && location.search.indexOf('arms_debug=true') > -1;
};

export const log = (...args: any[]) => {
  if (isDebug()) {
    // eslint-disable-next-line no-console
    console.log('[cone-arms debug]', ...args);
  }
};

export const supportLCP = () => {
  return (
    typeof PerformanceObserver === 'function' &&
    Array.isArray(PerformanceObserver.supportedEntryTypes) &&
    PerformanceObserver.supportedEntryTypes.indexOf('largest-contentful-paint') > -1
  );
};

export const debugReport = (logItem: Omit<LogCustomItem, 'p1'>) => {
  if (inWindow() && window.coneArmsTrace && window.coneArmsTrace.logCustom) {
    window.coneArmsTrace.logCustom({
      pid: 'cone-arms-debug',
      p1: 'debug-report',
      c1: window.coneArmsTrace.getConfig().pid,
      ...logItem,
    });
  }
};

export const getCNUIVersionByCdnLinks = () => {
  // get all script includes "cn-ui" in document
  const scripts = document.querySelectorAll(
    'script[src*="cn-ui"]',
  ) as unknown as HTMLScriptElement[];
  // get all src array from scripts
  const srcs = Array.from(scripts).map((script) => script.src);
  let cnuiVersion;
  let cnuimVersion;
  let cnuioVersion;
  let cnuimoVersion;
  let cnuilVersion;
  let cnuimlVersion;
  srcs.forEach((src) => {
    if (src.includes('/dist/cn-ui-m-onecode.js')) {
      cnuimoVersion = src.split('/dist/cn-ui-m-onecode.js')[0].split('/').pop();
    } else if (src.includes('/dist/cn-ui-onecode.js')) {
      cnuioVersion = src.split('/dist/cn-ui-onecode.js')[0].split('/').pop();
    } else if (src.includes('/cn-ui/cn-ui/')) {
      cnuiVersion = src.split('/cn-ui/cn-ui/')[1].split('/')[0];
    } else if (src.includes('/cn-ui/cn-ui-m/')) {
      cnuimVersion = src.split('/cn-ui/cn-ui-m/')[1].split('/')[0];
    } else if (src.includes('/cn-ui/cn-ui-onecode/')) {
      cnuioVersion = src.split('/cn-ui/cn-ui-onecode/')[1].split('/')[0];
    } else if (src.includes('/cn-ui/cn-ui-m-onecode/')) {
      cnuimoVersion = src.split('/cn-ui/cn-ui-m-onecode/')[1].split('/')[0];
    } else if (src.includes('/cn-ui/cn-ui-lowcode/')) {
      cnuilVersion = src.split('/cn-ui/cn-ui-lowcode/')[1].split('/')[0];
    } else if (src.includes('/cn-ui/cn-ui-m-lowcode/')) {
      cnuimlVersion = src.split('/cn-ui/cn-ui-m-lowcode/')[1].split('/')[0];
    }
  });
  const versionMap: Record<string, string | undefined> = {
    'cn-ui': cnuiVersion,
    'cn-ui-m': cnuimVersion,
    'cn-ui-onecode': cnuioVersion,
    'cn-ui-m-onecode': cnuimoVersion,
    'cn-ui-lowcode': cnuilVersion,
    'cn-ui-m-lowcode': cnuimlVersion,
  };
  return Object.keys(versionMap).reduce((acc, key) => {
    if (versionMap[key]) {
      return {
        ...acc,
        [key]: versionMap[key],
      };
    }
    return acc;
  }, {});
};

export const getCNUIVersion = () => {
  if (inWindow()) {
    const win = window as any;
    const result: Record<string, string> = {};
    const varsMap: Record<string, string> = {
      'cn-ui': 'CN_UI_VERSION',
      'cn-ui-m': 'CN_UI_M_VERSION',
      'cn-ui-onecode': 'CN_UI_ONECODE_VERSION',
      'cn-ui-m-onecode': 'CN_UI_M_ONECODE_VERSION',
      'cn-ui-lowcode': 'CN_UI_LOWCODE_VERSION',
      'cn-ui-m-lowcode': 'CN_UI_M_LOWCODE_VERSION',
    };
    Object.keys(varsMap).forEach((key) => {
      const varName = varsMap[key];
      if (win[varName]) {
        result[key] = win[varName];
      }
    });
    if (Object.keys(result).length > 0) {
      return result;
    } else {
      return getCNUIVersionByCdnLinks();
    }
  }
  return {};
};

// 从 cookie 中获取指定的值
export const getCookie = (name: string) => {
  if (inWindow()) {
    try {
      const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
      if (match) {
        return match[2];
      }
    } catch (e) {
      // ignore
    }
  }
  return '';
};

export const getClientInfo = (ua = '') => {
  try {
    const parser = new UAParser();
    if (ua) {
      parser.setUA(ua);
    }
    const result = parser.getResult();
    return {
      browser_name: result.browser.name || '',
      browser_version: result.browser.version || '',
      os: result.os.name || '',
      os_version: result.os.version || '',
      device_brand: result.device.vendor || '',
      device_model: result.device.model || '',
    };
  } catch (e) {
    return {};
  }
};
