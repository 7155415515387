import { inWindow, hookHistory, log } from '../utils';
import { ITraceWithCustomMethods } from '../types';
import RequestObserver from './request-observer';
import InputObserver from './input-observer';

declare global {
  interface Window {
    PerformanceLongTaskTiming?: any;
    LayoutShiftAttribution?: any;
  }
}

/**
 * @method MCP
 * @param {Function} callback 回调
 * @param {Object} options 自定义参数
 * @returns
 */
function onMCP(
  core: ITraceWithCustomMethods<any>,
  options: {
    start: number;
    onlyHookStaticRequest: boolean;
  },
) {
  // 最后一次DOM变化、Layout shift、element timing促发时间
  let LP: number;
  // 最后一次Longtask、请求结束的时间
  // let LastTaskEndTime;

  // 开始的时间点
  const { start = performance.now(), onlyHookStaticRequest = false } = options;

  let checkTimer: any;
  let observerMutation: MutationObserver;
  let observerLongTask: PerformanceObserver;
  let observerLS: PerformanceObserver;
  // observerElement,
  let observerRequest: RequestObserver;
  let observerInput: InputObserver;
  let hadReport = false;

  let visibilitychangeHandler: any = function () {
    if (document.hidden) {
      endObserve();
    }
  };

  const finish = function () {
    if (hadReport) {
      return;
    }
    hadReport = true;
    endObserve();
    const duration = (LP || performance.now()) - start;
    log('MCP duration', duration);
    core.logPerf({
      lcp: duration,
      c10: 'MCP',
    });
  };

  const checkAfter100ms = function () {
    const invoke = () => {
      // 计时器被正常执行，且当前没有进行中的请求，则认为首屏渲染完成
      if (observerRequest && observerRequest.requestStackSize < 1) {
        finish();
      }
    };
    if (typeof requestAnimationFrame === 'function') {
      if (cancelAnimationFrame && checkTimer) {
        cancelAnimationFrame(checkTimer);
      }
      checkTimer = requestAnimationFrame(() => {
        requestAnimationFrame(invoke);
      });
    } else {
      checkTimer && clearTimeout(checkTimer);
      checkTimer = setTimeout(invoke, 100);
    }
  };

  // 结束检测
  const endObserve = function () {
    checkTimer && clearTimeout(checkTimer);
    checkTimer = null;

    [observerMutation, observerLS, observerLongTask, observerRequest, observerInput].forEach(
      (item) => {
        item && item.disconnect();
      },
    );
    // observerElement && observerElement.disconnect();
    visibilitychangeHandler &&
      window.removeEventListener('visibilitychange', visibilitychangeHandler);
    visibilitychangeHandler = null;

    // start是0代表是页面onload前开始检测的，才会有load事件监听
    start === 0 && window.removeEventListener('load', startObserve);
  };

  // 开始进行检测
  const startObserve = function () {
    // Case1：请求检测
    observerRequest = new RequestObserver(checkAfter100ms, onlyHookStaticRequest);
    observerRequest.observe();

    // 检测DOM变化：确定最后一次渲染时间
    observerMutation = new MutationObserver((mutationsList) => {
      LP = performance.now();

      // Case1：img、js、css的加载
      mutationsList?.forEach((mutation) => {
        if (mutation.type === 'childList') {
          mutation?.addedNodes?.forEach((el: any) => {
            if (
              ((el.tagName === 'SCRIPT' || el.tagName === 'IMG') && el.src) ||
              (el.tagName === 'LINK' && el.rel === 'stylesheet' && el.href)
            ) {
              observerRequest && observerRequest.observe(el);
            }
          });
        }
      });
    });
    observerMutation.observe(document.documentElement, {
      attributes: true,
      childList: true,
      subtree: true,
    });

    // Case2：layout shift检测
    if (window.LayoutShiftAttribution) {
      observerLS = new PerformanceObserver(() => {
        // console.warn('出现layout shift');
        LP = performance.now();
        checkAfter100ms();
      });
      // type有兼容性问题，需要用entryTypes
      observerLS.observe({ entryTypes: ['layout-shift'] });
    }

    // Case3：element检测
    // if(window.PerformanceElementTiming){
    //     observerElement = new PerformanceObserver(function(){
    //         console.warn('出现element');
    //         LP = performance.now();
    //         checkAfter100ms();
    //     });
    //     observerElement.observe({entryTypes: ['element']});
    // }

    // Case4：longtask检测
    observerLongTask = new PerformanceObserver((list) => {
      // eslint-disable-next-line array-callback-return
      list.getEntries().some((entry) => {
        if (entry.duration > 50) {
          // console.warn('出现长任务');
          // LastTaskEndTime = performance.now();
          checkAfter100ms();
        }
      });
    });
    observerLongTask.observe({ entryTypes: ['longtask'] });

    // Case5：用户输入检测
    // 用户有操作就丢弃这一次采集
    observerInput = new InputObserver(() => {
      endObserve();
    });
    observerInput.observer();

    // Case6：页面离开
    window.addEventListener('visibilitychange', visibilitychangeHandler);

    checkAfter100ms();
  };

  startObserve();

  return {
    abort: endObserve,
    finish,
  };
}

let perfInstance: any = null;

const TraceMCPPlugin = (core: ITraceWithCustomMethods<any>, options: any) => {
  if (
    !inWindow() ||
    !window.PerformanceLongTaskTiming ||
    !window.MutationObserver ||
    !window?.performance?.now
  ) {
    return [];
  }
  // 性能上报需要单例，不重复上报
  if (perfInstance && perfInstance.uninstallHooks) {
    return perfInstance.uninstallHooks;
  }
  try {
    perfInstance = {
      reportInstance: null,
      uninstallHooks: [],
    };
    if (options?.auto === true) {
      perfInstance.uninstallHooks.push(
        hookHistory(() => {
          if (perfInstance.reportInstance) {
            perfInstance.reportInstance.abort();
          }
          perfInstance.reportInstance = onMCP(core, {
            onlyHookStaticRequest: true,
            start: performance.now(),
          });
        }),
      );
      perfInstance.uninstallHooks.push(() => {
        if (perfInstance.reportInstance) {
          perfInstance.reportInstance.abort();
          perfInstance.reportInstance = null;
        }
      });
    } else {
      perfInstance.uninstallHooks.push(() => {
        // eslint-disable-next-line no-param-reassign
        core.markSPAChanged = () => {};
      });
      let start: number | null = null;
      // eslint-disable-next-line no-param-reassign
      core.markSPAChanged = (eventType: 'start' | 'end') => {
        log('MCP: markSPAChanged called, eventType=', eventType);
        if (eventType === 'start') {
          start = performance.now();
        } else if (eventType === 'end' && start !== null) {
          const duration = performance.now() - start;
          start = null;
          log('MCP: duration=', duration);
          core.logPerf({
            lcp: duration,
            c10: 'MCP',
          });
        }
      };
    }
    return perfInstance.uninstallHooks;
  } catch (e) {
    console.warn(e);
  }
  return [];
};

if (inWindow()) {
  (window as any).TraceMCPPlugin = TraceMCPPlugin;
}

export default TraceMCPPlugin;
