/**
 * 判断是否是ftp页面
 * @returns true表示是ftp页面，false表示不是ftp页面
 */
export function isFTPPage() {
  const filter = getFilter();
  const table = getTable();
  if (filter && table) {
    return true;
  }
  return false;
}

/**
 * 查询fiter高度
 * @returns  fiter高度
 */
export function queryFilterHeight() {
  const filter = getFilter();
  if (filter) {
    return filter.clientHeight;
  }
  return 0;
}

/**
 * 查询table高度
 * @returns table高度
 */
export function queryTableHeight() {
  const table = getTable();
  if (table) {
    return table.clientHeight;
  }
  return 0;
}

/**
 * 查询table宽度
 * @returns table宽度
 */
export function queryTableWidth() {
  const table = getTable();
  if (table) {
    return table.clientWidth;
  }
  return 0;
}

/**
 * 查询page高度
 * @returns page高度
 */
export function queryPageHeight() {
  const page = getPage();
  if (page) {
    return page.clientHeight;
  }
  return 0;
}

/**
 * 获取当前竖向滚动的table的高度
 * @returns table实例
 */
export function queryTableVertical() {
  const table = document.querySelector('[data-scoll-element-type="vertical-table"]');
  return table;
}

/**
 * 获取当前横向滚动的table的高度
 * @returns table实例
 */
export function queryTableHorizontal() {
  const table = document.querySelector('[data-scoll-element-type="horizontal-table"]');
  return table;
}

/**
 * 获取page
 * @returns page
 */
export function getPage() {
  return document.querySelector('[data-scoll-element-type="page"]');
}

/**
 * 获取filter
 * @returns filter
 */
function getFilter() {
  return document.querySelector('[data-name="CnFilter"]');
}

/**
 * 获取table
 * @returns table
 */
function getTable() {
  return document.querySelector('[data-name="CnTable"]');
}
