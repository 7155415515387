import { uuid } from '@alife/cone-arms-core';
import {
  COOKIE_UID_KEY,
  COOKIE_UID_MAXAGE,
  APLUS_HOST,
  PREF_APLUS_LOGKEY,
  APLUS_LOGKEY,
} from './const';

/**
 * 获取url
 */
export const getUrl = () => {
  return window.location.hostname + window.location.pathname;
};

/**
 * 获取或生产uid，web浏览器端
 */
export const getWebUid = () => {
  if (typeof document === 'object') {
    let reg;
    let matches;
    // 监控页面被内嵌到未设置allow-same-origin的sandbox iframe中，会产生读取doc.cookie报错的情况
    try {
      reg = new RegExp(`${COOKIE_UID_KEY}=([^;]+)`);
      matches = reg.exec(document.cookie);
      if (matches) {
        return matches[1] || '';
      } else {
        const uid = uuid();
        document.cookie = `${COOKIE_UID_KEY}=${uid}; max-age=${COOKIE_UID_MAXAGE}`;
        return uid;
      }
    } catch (err) {
      return '';
    }
  }
  return '';
};

/**
 * 获取或生产uid，小程序端
 */
export const getMiniAppUid = () => {
  const miniApp =
    (typeof wx === 'object' && wx) ||
    (typeof my === 'object' && my) ||
    (typeof dd === 'object' && dd);
  if (miniApp && typeof miniApp.getStorageSync === 'function') {
    try {
      // 微信和阿里小程序getStorageSync入参不同
      // @ts-ignore
      let uid =
        typeof wx === 'object'
          ? miniApp.getStorageSync(COOKIE_UID_KEY)
          : miniApp.getStorageSync({ key: COOKIE_UID_KEY })?.data;
      if (uid && typeof uid === 'string') {
        return uid;
      } else if (typeof miniApp.setStorageSync === 'function') {
        // 否则重新生成一个随机guid，并设置和保存这个值
        uid = uuid();
        // 微信和阿里小程序setStorageSync入参不同
        // @ts-ignore
        typeof wx === 'object'
          ? miniApp.setStorageSync(COOKIE_UID_KEY, uid)
          : miniApp.setStorageSync({
              key: COOKIE_UID_KEY,
              data: uid,
            });
        return uid;
      }
    } catch (err) {
      return '';
    }
  }
  return '';
};

export const isNotEmptyFunction = (func: Function) => {
  try {
    const code = Function.prototype.toString.call(func);
    const match = /\{(.*)\}/.exec(code);
    const body = match ? match[1] : '';
    return body !== '';
  } catch (e) {
    return false;
  }
};

export function getSendInfo(type?: string, aplusHost?: string) {
  const host = aplusHost && typeof aplusHost === 'string' ? aplusHost : APLUS_HOST;
  const logKey = type === 'perf' ? PREF_APLUS_LOGKEY : APLUS_LOGKEY;
  const url = `https://${host}${APLUS_LOGKEY}`;
  return { APLUS_LOGKEY: logKey, APLUS_URL: url };
}
